import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../package.json";
import { Avatar } from '../src/index';
import { DebugHappeoThemeProvider } from "@happeouikit/theme";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "avatar"
    }}>{`Avatar`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <DebugHappeoThemeProvider mdxType="DebugHappeoThemeProvider" />
    <h3 {...{
      "id": "hover-event"
    }}>{`Hover Event`}</h3>
    <Playground __position={2} __code={'<Avatar\n  size=\"l\"\n  hoverEvent\n  user={{\n    thumbnailPhotoUrl:\n      \'https://storage.googleapis.com/spacegapps_uploads/b18cde23-2942-4ef0-8eba-9a059a89c675?etag=TN30oD80QTVK45AAxvl_wbzs4vs/hW48jDsWiDIX8EuYnCnCVVk9bHE\',\n    fullName: \'Diane Yellow\',\n  }}\n/>\n<Avatar\n  size=\"l\"\n  hoverEvent\n  user={{\n    thumbnailPhotoUrl:\n      \'https://cdn0.iconfinder.com/data/icons/avatar-set-one/128/avatar-27-512.png\',\n    fullName: \'Diane Yellow\',\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Avatar,
      DebugHappeoThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Avatar size='l' hoverEvent user={{
        thumbnailPhotoUrl: 'https://storage.googleapis.com/spacegapps_uploads/b18cde23-2942-4ef0-8eba-9a059a89c675?etag=TN30oD80QTVK45AAxvl_wbzs4vs/hW48jDsWiDIX8EuYnCnCVVk9bHE',
        fullName: 'Diane Yellow'
      }} mdxType="Avatar" />
  <Avatar size='l' hoverEvent user={{
        thumbnailPhotoUrl: 'https://cdn0.iconfinder.com/data/icons/avatar-set-one/128/avatar-27-512.png',
        fullName: 'Diane Yellow'
      }} mdxType="Avatar" />
    </Playground>
    <h3 {...{
      "id": "click-event"
    }}>{`Click Event`}</h3>
    <Playground __position={3} __code={'<Avatar\n  size=\"l\"\n  clickEvent\n  onClick={() => console.log(\'clicked\')}\n  ariaLabel=\"Clickedi this\"\n  user={{\n    thumbnailPhotoUrl:\n      \'https://storage.googleapis.com/spacegapps_uploads/b18cde23-2942-4ef0-8eba-9a059a89c675?etag=TN30oD80QTVK45AAxvl_wbzs4vs/hW48jDsWiDIX8EuYnCnCVVk9bHE\',\n    fullName: \'Diane Yellow\',\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Avatar,
      DebugHappeoThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Avatar size='l' clickEvent onClick={() => console.log('clicked')} ariaLabel="Clickedi this" user={{
        thumbnailPhotoUrl: 'https://storage.googleapis.com/spacegapps_uploads/b18cde23-2942-4ef0-8eba-9a059a89c675?etag=TN30oD80QTVK45AAxvl_wbzs4vs/hW48jDsWiDIX8EuYnCnCVVk9bHE',
        fullName: 'Diane Yellow'
      }} mdxType="Avatar" />
    </Playground>
    <h3 {...{
      "id": "click-event-without-arialabel-will-throw-an-error"
    }}>{`Click Event without ariaLabel, will throw an error`}</h3>
    <Playground __position={4} __code={'<Avatar\n  size=\"l\"\n  clickEvent\n  onClick={() => console.log(\'clicked\')}\n  user={{\n    thumbnailPhotoUrl:\n      \'https://storage.googleapis.com/spacegapps_uploads/b18cde23-2942-4ef0-8eba-9a059a89c675?etag=TN30oD80QTVK45AAxvl_wbzs4vs/hW48jDsWiDIX8EuYnCnCVVk9bHE\',\n    fullName: \'Diane Yellow\',\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Avatar,
      DebugHappeoThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Avatar size='l' clickEvent onClick={() => console.log('clicked')} user={{
        thumbnailPhotoUrl: 'https://storage.googleapis.com/spacegapps_uploads/b18cde23-2942-4ef0-8eba-9a059a89c675?etag=TN30oD80QTVK45AAxvl_wbzs4vs/hW48jDsWiDIX8EuYnCnCVVk9bHE',
        fullName: 'Diane Yellow'
      }} mdxType="Avatar" />
    </Playground>
    <h3 {...{
      "id": "initials-and-user-specific-background-color-when-thumbnail-is-not-set"
    }}>{`Initials and user specific background color when thumbnail is not set`}</h3>
    <Playground __position={5} __code={'<Avatar\n  size=\"l\"\n  clickEvent\n  onClick={() => console.log(\'clicked\')}\n  ariaLabel=\"Clickedi this\"\n  user={{ fullName: \'Diane Yellow\' }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Avatar,
      DebugHappeoThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Avatar size='l' clickEvent onClick={() => console.log('clicked')} ariaLabel="Clickedi this" user={{
        fullName: 'Diane Yellow'
      }} mdxType="Avatar" />
    </Playground>
    <h3 {...{
      "id": "fallback-image-when-user-does-not-have-thumbnail-or-name"
    }}>{`Fallback image when user does not have thumbnail or name`}</h3>
    <Playground __position={6} __code={'<Avatar\n  size=\"l\"\n  clickEvent\n  onClick={() => console.log(\'clicked\')}\n  ariaLabel=\"Clickedi this\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Avatar,
      DebugHappeoThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Avatar size='l' clickEvent onClick={() => console.log('clicked')} ariaLabel="Clickedi this" mdxType="Avatar" />
    </Playground>
    <h3 {...{
      "id": "fallback-to-initials-if-thumbnail-cannot-be-downloaded"
    }}>{`Fallback to initials if thumbnail cannot be downloaded`}</h3>
    <Playground __position={7} __code={'<Avatar\n  size=\"l\"\n  clickEvent\n  user={{\n    thumbnailPhotoUrl: \'doesnotexist.happeo.com\',\n    fullName: \'Diane Yellow\',\n  }}\n  onClick={() => console.log(\'clicked\')}\n  ariaLabel=\"Clickedi this\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Avatar,
      DebugHappeoThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Avatar size='l' clickEvent user={{
        thumbnailPhotoUrl: 'doesnotexist.happeo.com',
        fullName: 'Diane Yellow'
      }} onClick={() => console.log('clicked')} ariaLabel="Clickedi this" mdxType="Avatar" />
    </Playground>
    <h3 {...{
      "id": "group-icon-for-groups"
    }}>{`Group icon for groups`}</h3>
    <Playground __position={8} __code={'<Avatar size=\"l\" user={{ isGroup: true }} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Avatar,
      DebugHappeoThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Avatar size='l' user={{
        isGroup: true
      }} mdxType="Avatar" />
    </Playground>
    <h3 {...{
      "id": "presence"
    }}>{`Presence`}</h3>
    <p>{`Presence status can also be shown on avatars bigger than size `}<em parentName="p">{`xs`}</em>{` with property `}<inlineCode parentName="p">{`showPresence`}</inlineCode>{`.`}</p>
    <Playground __position={9} __code={'<Avatar\n  size=\"m\"\n  clickEvent\n  onClick={() => console.log(\'clicked\')}\n  ariaLabel=\"Clickedi this\"\n  user={{\n    thumbnailPhotoUrl:\n      \'https://storage.googleapis.com/spacegapps_uploads/b18cde23-2942-4ef0-8eba-9a059a89c675?etag=TN30oD80QTVK45AAxvl_wbzs4vs/hW48jDsWiDIX8EuYnCnCVVk9bHE\',\n    fullName: \'Diane Yellow\',\n    status: \'free\',\n  }}\n  showPresence\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Avatar,
      DebugHappeoThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Avatar size='m' clickEvent onClick={() => console.log('clicked')} ariaLabel="Clickedi this" user={{
        thumbnailPhotoUrl: 'https://storage.googleapis.com/spacegapps_uploads/b18cde23-2942-4ef0-8eba-9a059a89c675?etag=TN30oD80QTVK45AAxvl_wbzs4vs/hW48jDsWiDIX8EuYnCnCVVk9bHE',
        fullName: 'Diane Yellow',
        status: 'free'
      }} showPresence mdxType="Avatar" />
    </Playground>
    <h4 {...{
      "id": "emoji-presence"
    }}>{`Emoji Presence`}</h4>
    <p>{`You can pass emojis to be shown inside of the presence status for sizes bigger than xs.`}</p>
    <Playground __position={10} __code={'<Avatar\n  size=\"s\"\n  clickEvent\n  onClick={() => console.log(\'clicked\')}\n  ariaLabel=\"Clickedi this\"\n  user={{\n    thumbnailPhotoUrl: \'https://source.unsplash.com/random/800x600\',\n    fullName: \'Diane Yellow\',\n    status: \'free\',\n  }}\n  showPresence\n  emoji=\"👍\"\n/>\n<br />\n<Avatar\n  size=\"m\"\n  clickEvent\n  onClick={() => console.log(\'clicked\')}\n  ariaLabel=\"Clickedi this\"\n  user={{\n    thumbnailPhotoUrl: \'https://source.unsplash.com/random/1280x760\',\n    fullName: \'Diane Yellow\',\n    status: \'free\',\n  }}\n  showPresence\n  emoji=\"😛\"\n/>\n<br />\n<Avatar\n  size=\"l\"\n  clickEvent\n  onClick={() => console.log(\'clicked\')}\n  ariaLabel=\"Clickedi this\"\n  user={{\n    thumbnailPhotoUrl: \'https://source.unsplash.com/random/640x480\',\n    fullName: \'Diane Yellow\',\n    status: \'free\',\n  }}\n  showPresence\n  emoji=\"🎅\"\n/>\n<br />\n<Avatar\n  size=\"xl\"\n  clickEvent\n  onClick={() => console.log(\'clicked\')}\n  ariaLabel=\"Clickedi this\"\n  user={{\n    thumbnailPhotoUrl: \'https://source.unsplash.com/random/900x300\',\n    fullName: \'Diane Yellow\',\n    status: \'free\',\n  }}\n  showPresence\n  emoji=\"🥰\"\n/>\n<br />\n<Avatar\n  size=\"xxl\"\n  clickEvent\n  onClick={() => console.log(\'clicked\')}\n  ariaLabel=\"Clickedi this\"\n  user={{\n    thumbnailPhotoUrl: \'https://source.unsplash.com/random/1920x1080\',\n    fullName: \'Diane Yellow\',\n    status: \'free\',\n  }}\n  showPresence\n  emoji=\"😕\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Avatar,
      DebugHappeoThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Avatar size='s' clickEvent onClick={() => console.log('clicked')} ariaLabel="Clickedi this" user={{
        thumbnailPhotoUrl: 'https://source.unsplash.com/random/800x600',
        fullName: 'Diane Yellow',
        status: 'free'
      }} showPresence emoji="👍" mdxType="Avatar" />
  <br />
  <Avatar size='m' clickEvent onClick={() => console.log('clicked')} ariaLabel="Clickedi this" user={{
        thumbnailPhotoUrl: 'https://source.unsplash.com/random/1280x760',
        fullName: 'Diane Yellow',
        status: 'free'
      }} showPresence emoji="😛" mdxType="Avatar" />
  <br />
  <Avatar size='l' clickEvent onClick={() => console.log('clicked')} ariaLabel="Clickedi this" user={{
        thumbnailPhotoUrl: 'https://source.unsplash.com/random/640x480',
        fullName: 'Diane Yellow',
        status: 'free'
      }} showPresence emoji="🎅" mdxType="Avatar" />
  <br />
  <Avatar size='xl' clickEvent onClick={() => console.log('clicked')} ariaLabel="Clickedi this" user={{
        thumbnailPhotoUrl: 'https://source.unsplash.com/random/900x300',
        fullName: 'Diane Yellow',
        status: 'free'
      }} showPresence emoji="🥰" mdxType="Avatar" />
  <br />
  <Avatar size='xxl' clickEvent onClick={() => console.log('clicked')} ariaLabel="Clickedi this" user={{
        thumbnailPhotoUrl: 'https://source.unsplash.com/random/1920x1080',
        fullName: 'Diane Yellow',
        status: 'free'
      }} showPresence emoji="😕" mdxType="Avatar" />
    </Playground>
    <h3 {...{
      "id": "sizes"
    }}>{`Sizes`}</h3>
    <Playground __position={11} __code={'<Avatar size=\"xxs\" user={{ fullName: \'Diane Yellow\' }} />\n<Avatar size=\"xs\" user={{ fullName: \'Diane Yellow\' }} />\n<Avatar size=\"s\" user={{ fullName: \'Diane Yellow\' }} />\n<Avatar size=\"m\" user={{ fullName: \'Diane Yellow\' }} />\n<Avatar size=\"l\" user={{ fullName: \'Diane Yellow\' }} />\n<Avatar size=\"xl\" user={{ fullName: \'Diane Yellow\' }} />\n<Avatar size=\"xxl\" user={{ fullName: \'Diane Yellow\' }} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Avatar,
      DebugHappeoThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Avatar size='xxs' user={{
        fullName: 'Diane Yellow'
      }} mdxType="Avatar" />
  <Avatar size='xs' user={{
        fullName: 'Diane Yellow'
      }} mdxType="Avatar" />
  <Avatar size='s' user={{
        fullName: 'Diane Yellow'
      }} mdxType="Avatar" />
  <Avatar size='m' user={{
        fullName: 'Diane Yellow'
      }} mdxType="Avatar" />
  <Avatar size='l' user={{
        fullName: 'Diane Yellow'
      }} mdxType="Avatar" />
  <Avatar size='xl' user={{
        fullName: 'Diane Yellow'
      }} mdxType="Avatar" />
  <Avatar size='xxl' user={{
        fullName: 'Diane Yellow'
      }} mdxType="Avatar" />
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Avatar} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      